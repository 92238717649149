
import config from '../../config/config.json';
// import Cookies from 'js-cookie';
import { localStorageUtils } from '../../actions/common';
import { service } from "../../actions/middleware";
import { Adobe } from '../../actions/adobeLayer';
export function arrayToObject(array) {
    let object = null;
    if (array.length > 0) 
        object = array[0];
    return object;
}
export function componentPositionHandling(componentData, userTier, isMobile){
    // component name sorting asc based on position
    let positonProp = 'ComponentPositionWeb';
    let typeProp = 'ComponentTypeWeb';
    if (isMobile) {
        positonProp = 'ComponentPositionMoWeb';
        typeProp = 'ComponentTypeMoWeb';
    }
    componentData = componentData.filter(el => el[typeProp] != "didYouKnow");
    let componentPosition = [];
    componentData = componentData.filter(comp => comp[positonProp] != "" && !isNaN(comp[positonProp]));
    componentData = componentData.sort((a, b) => {
        return +a[positonProp] - +b[positonProp];   
    });
    componentData.forEach(comp => {
        componentPosition.push(comp[typeProp]);
    })
    // removing not used login component from array
    if (config.landingPageJSON.tierDependency.loginComponents.eliteTierBenefits.includes(userTier)) {
        componentPosition = componentPosition.filter(e => e != "loginPersuasion" && e != "redTierBenefits" && e != "levelUp");
    } else if (config.landingPageJSON.tierDependency.loginComponents.levelUp.includes(userTier)) {
        componentPosition = componentPosition.filter(e => e != "loginPersuasion" && e != "redTierBenefits" && e != "eliteTierBenefits")
    } else if (config.landingPageJSON.tierDependency.loginComponents.redTierBenefits.includes(userTier)) {
        componentPosition = componentPosition.filter(e => e != "loginPersuasion" && e != "levelUp" && e != "eliteTierBenefits")
    } else {
        componentPosition = componentPosition.filter(e => e != "redTierBenefits" && e != "levelUp" && e != "eliteTierBenefits")
    }
    // renaming different login component as loginComponent
    componentPosition = componentPosition.map(e => {
        if (e == "eliteTierBenefits" || e == "loginPersuasion" || e == "redTierBenefits" || e == "levelUp") {
            e = "loginComponent";
        }
        return e;
    })
    componentPosition = componentPosition.filter(e => e != "downloadapp");
    // adding resumebooking component
    let firstTwoComponents = [...componentPosition].splice(0, 2);
    let otherComponents = [...componentPosition].splice(2, componentPosition.length);
    componentPosition = [...firstTwoComponents, 'resumeBooking', ...otherComponents];
    return componentPosition;
}
function loginJSONHandler(userTier, data, isMobile){
    let keyToCheck = "ComponentTypeWeb";
    if (isMobile) {
        keyToCheck = "ComponentTypeMoWeb";
    }
    let loginJSONArray = null;
    if (config.landingPageJSON.tierDependency.loginComponents.loginPersuasion.includes(userTier)) {
        loginJSONArray = data.filter(e => e[keyToCheck] == "loginPersuasion");
    } else if (config.landingPageJSON.tierDependency.loginComponents.levelUp.includes(userTier)) {
        loginJSONArray = data.filter(e => e[keyToCheck] == "levelUp");
    } else if (config.landingPageJSON.tierDependency.loginComponents.redTierBenefits.includes(userTier)) {
        loginJSONArray = data.filter(e => e[keyToCheck] == "redTierBenefits");
    } else if (config.landingPageJSON.tierDependency.loginComponents.eliteTierBenefits.includes(userTier)) {
        loginJSONArray = data.filter(e => e[keyToCheck] == "eliteTierBenefits");
    }
    loginJSONArray = arrayToObject(loginJSONArray);
    return loginJSONArray;
}
export function jsonHandling(jsonData ,userdetails, isMobile, searchType, isSSR, screenWidth) {
    let keyToCheck = "ComponentTypeWeb";
    let homePageDataCustom = [];
    if (Object.values(jsonData.mobilecontainer)?.length > 0 ) {
        Object.values(jsonData.mobilecontainer).forEach(data => {
            if (data.length) {
                data.forEach(d => homePageDataCustom.push(d))
            }else {
                homePageDataCustom.push(data)
            }
        })
    }
    let imageDomainUrl = null;
    if (config.landingPageJSON.domainUrl) {
        imageDomainUrl = config.landingPageJSON.domainUrl
    }
    let loginInfo = userdetails
    if (config.staticLogin) {
        loginInfo = config.staticLoginObj;
    }
    let userTier = loginInfo.isLoggedIn ? loginInfo?.tier?.toLocaleLowerCase() : 'guest';
    let loginComponentJSON = loginJSONHandler(userTier, homePageDataCustom, isMobile);
    // Seo Flight Section
    let seoSectionOne = [];
    let seoSectionTwo = [];
    let seoSectionThree = [];
    let SeoFlight = homePageDataCustom.filter(seo => seo[keyToCheck] == "genericRouteInfo");
    if (SeoFlight.length > 0) {
        let multiply = 1;
        SeoFlight[0].seoContainer.map((seo, index) => {
            if (index < 3) {
                if (index == 0) {
                    seoSectionOne.push(seo);
                } else if (index == 1) {
                    seoSectionTwo.push(seo);
                } else if (index == 2) {
                    seoSectionThree.push(seo);
                }
            } else {
                let ind = index - (3 * multiply);
                if (ind == 0) {
                    seoSectionOne.push(seo);
                } else if (ind == 1) {
                    seoSectionTwo.push(seo);
                } else if (ind == 2) {
                    seoSectionThree.push(seo);
                    multiply += 1;
                }
            }
        })
        SeoFlight = SeoFlight[0]
    } else {
        SeoFlight = null
    }
    // Popular Route Section
    let PopularFlight = homePageDataCustom.filter(seo => seo[keyToCheck] == "popularRoutes");
    let DomesticFlight = null;
    let InternationalFlight = null;
    // let resumeBookStorageData = getResumeBookingDataFromStorage(isSSR);
    let resumeBookCookie = getResumeBookingCookies(isSSR);
    if (PopularFlight.length > 0) {
        PopularFlight = PopularFlight[0]
        DomesticFlight = PopularFlight.seoContainer.filter(flight => flight.sectionHeading.toLocaleLowerCase().includes("domestic"));
        DomesticFlight = arrayToObject(DomesticFlight);
        InternationalFlight = PopularFlight.seoContainer.filter(flight => flight.sectionHeading.toLocaleLowerCase().includes("international"));
        InternationalFlight = arrayToObject(InternationalFlight);
    }
    // FAQ Section
    let faqData = homePageDataCustom.filter(faq => faq[keyToCheck] == "faqTnC");
    faqData = arrayToObject(faqData);
    // mark up FAQ
    let markupFAQ = null;
    if (faqData.AccordionContainer[0].Accordion.length > 0) {
        markupFAQ = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": []
        }
        faqData.AccordionContainer[0].Accordion.forEach(el => {
            markupFAQ.mainEntity.push({
                "@type": "Question",
                "name": el.contentHeadline,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": el.richTextContent
                }
            })
        })
    }
    // about section data
    let aboutJSON =  homePageDataCustom.filter(e => e[keyToCheck] == "aboutInterMiles");
    let aboutFirstSection = [];
    let aboutSeconfSection = [];
    if (aboutJSON.length > 0) {
        aboutJSON[0].contentBoxContent.map((abt) => {
            abt.Accordion.map((el, idx) => {
                if (idx % 2 == 0) {
                    aboutFirstSection.push(el);
                } else {
                    aboutSeconfSection.push(el);
                }
            })
        })
    }
    aboutJSON = arrayToObject(aboutJSON);
    // why book with intermiles section
    let whyBookW_IM = homePageDataCustom.filter(e => e[keyToCheck] == "bookWithInterMiles");
    whyBookW_IM = arrayToObject(whyBookW_IM);
    // Feature Section
    let featuresJSON = homePageDataCustom.filter(feature => feature[keyToCheck] == "getStarted");
    featuresJSON = arrayToObject(featuresJSON);
    featuresJSON = featureHandling(featuresJSON, userTier)
    // Guideline Section
    let guidelines = homePageDataCustom.filter(guideline => guideline[keyToCheck] == "genericInfo");
    guidelines = arrayToObject(guidelines);
    let downloadApp = homePageDataCustom.filter(guideline => guideline[keyToCheck] == "downloadapp");
    downloadApp = arrayToObject(downloadApp);
    
    if (downloadApp != null && guidelines.Items.length < 2) {
        guidelines.Items.push(downloadApp.Items[0])
    }
    // search widget section
    let searchWidgetJSON = homePageDataCustom.filter(sw => sw[keyToCheck] == "searchWidget");
    searchWidgetJSON = arrayToObject(searchWidgetJSON);
    // Offer section
    // let offers= await service(false, getOffers);
    let offerDetails = null;
    if (config.landingPageJSON.offerDependeny.includes(searchType)) {
        offerDetails = homePageDataCustom.filter(guideline => guideline[keyToCheck] == "offerDetails");
        offerDetails = arrayToObject(offerDetails);
    }
    // did you know section
    let didYouknow = homePageDataCustom.filter(e => e[keyToCheck] == "didYouKnow");
    
    didYouknow = arrayToObject(didYouknow);
    // position handling of components
    // read more states
    let readMoreStates = null;
    if (!isMobile) {
        readMoreStates = {
            ...buildReadMoreState('sectionOne', aboutFirstSection, false, isSSR, isMobile, screenWidth),
            ...buildReadMoreState('sectionTwo', aboutFirstSection, false, isSSR, isMobile, screenWidth),
        }
    } else {
        readMoreStates = {
            ...buildReadMoreState('mobileReadMore', aboutJSON.contentBoxContent, 'Accordion', isSSR, true, screenWidth)
        }
    }
    return {
        imageDomainUrl,
        userTier,
        seoSectionOne,
        seoSectionTwo,
        seoSectionThree,
        SeoFlight,
        DomesticFlight,
        InternationalFlight,
        aboutFirstSection,
        aboutSeconfSection,
        aboutJSON,
        whyBookW_IM,
        featuresJSON,
        guidelines,
        downloadApp,
        searchWidgetJSON,
        offerDetails,
        didYouknow,
        markupFAQ,
        faqData,
        componentPosition: componentPositionHandling(homePageDataCustom, userTier, isMobile),
        ...recentSearchRetrieve(isSSR),
        readMoreStates,
        loginComponentJSON,
        resumeBookCookie
    }
}
function recentSearchRetrieve(isSSR){
    let recentSearch = [];
    let earnMilesRecentSearch = [];
    let redeemMilesRecentSearch = [];
    if (!isSSR) {
        recentSearch = localStorageUtils.getItem('home_page_search');
        if (recentSearch) {
            recentSearch = JSON.parse(decodeURI(recentSearch));
            /* filtering based on depature date */
            recentSearch = recentSearch.filter(search => {
                let recentSearchDate = new Date(search.departureDate);
                let diffInTime = recentSearchDate.getTime() - new Date().getTime();
                let diffInDays = diffInTime / (1000 * 3600 * 24);
                if (diffInDays > 0) {
                    if (search.searchType == 'UBA')
                        earnMilesRecentSearch.push(search);
                    else 
                        redeemMilesRecentSearch.push(search);
                    return search
                }
            })
        } else {
            recentSearch = [];
        }
    }
    return {
        recentSearch,
        earnMilesRecentSearch,
        redeemMilesRecentSearch
    }
}
function featureHandling(feactureJSON, userTier) {
    let showFeature = false;
    let featureCarousal = false;
    let features = [];
    if (feactureJSON?.ListContent?.Content?.length > 0) {
        feactureJSON?.ListContent?.Content?.map(feature => {
            if (feature?.PersonalizationTags.includes(userTier)) {
                features.push(feature);
            }
        })
        showFeature = true;
        if (features?.length > 3) {
            featureCarousal = true;
        }
    }
    return {
        showFeature,
        featureCarousal,
        features
    }
}
function offersState (offers , isMobile){
    let keyToCheck = 'partnerCategoryDesktop';
    if (isMobile) {
        keyToCheck = 'partnerCategory'
    }
    let showOffers = false;
    let offerCarousal = false;
    let didYouKnowSection = 0;
    let flightOffer = [];
    if (offers) {
        flightOffer = offers?.offerContainer?.Items?.filter(offer => (offer?.[keyToCheck] == "FLIGHTS" || offer?.[keyToCheck] == "Flights" || offer?.[keyToCheck] == "flights") && new Date() < new Date(offer.offerValidity.replace(/-/g,"/")));
        if (flightOffer?.length > 0) {
            // sorting based on expiry date
            /* flightOffer.sort((a, b) => {
                return new Date(a.offerValidity.replace(/-/g,"/")).getTime() - new Date(b.offerValidity.replace(/-/g,"/")).getTime();
            }) */
            showOffers = true;
            if (flightOffer.length > 3) {
                offerCarousal = true;
            }
            if (flightOffer.length == 1) {
                didYouKnowSection = 1;
            } else if (flightOffer.length == 2) {
                didYouKnowSection = 2;
            }
        }
    }
    return {
        flightOffer,
        showOffers,
        offerCarousal,
        didYouKnowSection
    }
}
export function offerHandling(ubaOffers, ubrOffers, isMobile) {
    return {
        UBA: offersState(ubaOffers, isMobile),
        UBR: offersState(ubrOffers, isMobile)
    }
}
export const decodeHTML = (rawHTML, isSSR) => {
    if (!isSSR) {
        const txt = document.createElement('textarea');
        txt.innerHTML = rawHTML;
        let html = txt.value;
        txt.remove();
        return html;
    } else {
        return rawHTML;
    }
}
function buildReadMoreState(stateName, propName, propKey, isSSR, isMobile, screenWidth) {
    if (propName?.length > 0) {
        let accordionArray = propName;
        if (propKey) {
            accordionArray = accordionArray[0][propKey];
        }
        let readMoreStates = [];
        let compareValue = screenWidth;
        if (!isMobile) {
            compareValue /= 2;
        }
        compareValue /= 10;
        compareValue = Math.floor(compareValue);
        accordionArray?.map((el, ind) => {
            let content = el?.textContent.split(" ");
            if (el.textFormat == "rte") {
                content = decodeHTML(el?.richTextContent, isSSR).split(" ");
            }
            readMoreStates.push({
                id: ind,
                readMore: content.length > compareValue ? true : false,
                enoughText: content.length > compareValue ? true : false
            })
        })
        return {
            [stateName]:readMoreStates,
            compareValue,
        }
    }
}
export function formatdata(rawdata){
    const data = rawdata
    for(const [key,value] of Object.entries(data)){
      if(key == 0){
       data[0].heading = data[0].type == "popular"?"POPULAR CITIES": data[0].type == "recent"? "RECENT SEARCHS" : "SUGGESTIONS"
       if(data[0].type == "popular") break;
      }else if(value.type == "popular"){
       data[key].heading = "POPULAR CITIES";
       break;
      }else if (value.type == "recent") {
        data[key].heading = "RECENT SEARCHES";
      }
    }
    return data
}
export function getResumeBookingCookies(isSSR, isRawCookie) {
    let allCookies = [];
    if(!isSSR){
        let cookieValue = document.cookie.split('; ').filter(row => row.startsWith(config.resumeBookingConfig.keyName));
        let i;
        for(i=0; i<cookieValue.length; i++) {
            let value = cookieValue[i].split('=')[1];
            value = JSON.parse(value);
            if(isRawCookie) {
                return value;
            }
            let resumeBookingData = {};
            for (const keyValue of config.resumeBookingCookieKeys) {
                resumeBookingData[keyValue.name] = value[keyValue.index];
            }
            allCookies.push(resumeBookingData);
        }
    }
    return allCookies
}
export function getResumeBookReq(props){
    let cookieVal = props.cookieData;
    return {
        "origin": cookieVal.originCode,
        "destination": cookieVal.destinationCode,
        "triptype": cookieVal.journeyType === "Return" ? "RETURN" : "ONEWAY",
        "cabinClass": cookieVal.cabinType == "Economy" ? "E" : "B",
        "adults": cookieVal.adultsCount,
        "childs": cookieVal.childCount,
        "infants": cookieVal.infantCount,
        "excludeLCCSearch": false,
        "isNearByAirportSearch": false,
        "isNonStopSearch": false,
        "onwarddt": cookieVal.onwardArrivalDate,
        "returndt": cookieVal.returnArrivalDate,
        "flightType": cookieVal.flightType == "Domestic" ? "D": "I",
        "flighttype": cookieVal.flightType == "Domestic" ? "D": "I",
        "originCity": cookieVal.originCode,
        "destinationCity": cookieVal.destinationCode,
        "sessionId": "",
        "allAirport": true,
        "platform": "Web",
        // "onwardAirlineName": cookieVal.onwardAirlineName,
        // "returnAirlineName": cookieVal.returnAirlineName,
        "onwardAirlineCode": cookieVal.onwardAirlineCode,
        "returnAirlineCode": cookieVal.returnAirlineCode,
        "onwardFlightNum": cookieVal.onwardFlightNumber,
        "returnFlightNum": cookieVal.returnFlightNumber,
        "onwardArrivalTime": cookieVal.onwardArrivalTime,
        "returnArrivalTime": cookieVal.returnArrivalTime,
        "onwardDeptTime": cookieVal.onwardDeptTime,
        "returnDeptTime": cookieVal.returnDeptTime,
        "onwardDataLength": cookieVal.onwardLegLength,
        "returnDataLength": cookieVal.returnLegLength,
        "flightId": cookieVal.flightID,
        "onwardSaverType": cookieVal.onwardSaverType,
        "returnSaverType": cookieVal.returnSaverType,		
    }
}
export async function loadReviewPage (cookieMinutes, props){
    sessionStorage.removeItem("isOrderGenerated");
    sessionStorage.removeItem("generatedOrderID");

    let urlId = "";
    let resumBookingCookieUpdated = getResumeBookingCookies(false, true);
    if (cookieMinutes > config.resumeBookingConfig.bookNowButtonTimeInMinute) {
        let resumeBookReq = getResumeBookReq(props);
        let apiRespone = await service(resumeBookReq, "resumeBooking");
        if (apiRespone.status) {
            resumBookingCookieUpdated[0] = apiRespone.redirectionalUrl;
            urlId = apiRespone.redirectionalUrl;
        }
        else {
            urlId = props.cookieData.flightID;
        }
    } else {
            urlId = props.cookieData.flightID;

    }

    if(resumBookingCookieUpdated[resumBookingCookieUpdated.length-1] != 'Y'){
        resumBookingCookieUpdated[resumBookingCookieUpdated.length] = 'Y'
    }
    deleteResumeBookingCookiesServices();
    let expiryTime = config.resumeBookingConfig.expireTimeInDays * 24 * 60 * 60 * 1000;
    let d = new Date();
    d.setTime(d.getTime() + (expiryTime));
    let ctime = "expires=" + d.toUTCString();
    document.cookie = config.resumeBookingConfig.keyName + "=" + JSON.stringify(resumBookingCookieUpdated) + "; " + ctime + "; " + `${!config.resumeBookingConfig.isDevEnv ? 'domain=.intermiles.com ; ' : ''}` + "path=/";
    window.location.href = `${config.landingPageJSON.hostUrl}/earn-airmiles/search/itinerary/reviews/${urlId}`;

    Adobe.clickEvent("resume booking", "HOME",props.pageInfo,props.loggedIn_Info);
}
export function deleteResumeBookingCookiesServices(){
    // this.setState({
    //     cookieStatus: false
    // })
    let cookieValue = document.cookie.split('; ').filter(row => row.startsWith(config.resumeBookingConfig.keyName));
    let i; 
    for(i=0; i<cookieValue.length; i++) {
        let name = cookieValue[i].split('=')[0];
        document.cookie = name + "=;" + `${!config.resumeBookingConfig.isDevEnv ? 'domain=.intermiles.com ; ' : ''}` +";path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
    // localStorageUtils.removeItem(config.resumeBookingConfig.keyName);
}
export function checkIsMobile(userAgent){
    return Boolean(userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    ))
}

export function deleteAffiliateCookies() {
    var cookieValue = document.cookie.split('; ')
    .filter(row => row.startsWith("aff_id_"));

    for(var i=0; i<cookieValue.length; i++) {
        var name = cookieValue[i].split('=')[0];
        document.cookie = name + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}